<div class="wrapper">
  @if (!withoutIcon) {
    @if (customIcon) {
      <ng-content select="pxw-icon"></ng-content>
    } @else {
      @switch (type) {
        @case ('neutral') {
          <pxw-icon icon="info" [size]="iconSize" />
        }
        @case ('info') {
          <pxw-icon icon="info" [size]="iconSize" />
        }
        @case ('success') {
          <pxw-icon icon="check" [size]="iconSize" />
        }
        @case ('warning') {
          <pxw-icon icon="alert" [size]="iconSize" />
        }
        @case ('danger') {
          <pxw-icon icon="alert" [size]="iconSize" />
        }
      }
    }
  }
  @if (displayCloseButton) {
    <pxw-icon
      class="close-button ui-clickable"
      icon="close"
      size="xs"
      (click)="closeAlert.emit()"
    />
  }
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>

@if (alertDetails) {
  <div class="details">
    <ng-container *ngTemplateOutlet="alertDetails"></ng-container>
  </div>
}
@if (alertValidation) {
  <div class="validation">
    <ng-container *ngTemplateOutlet="alertValidation"></ng-container>
  </div>
}
