import { NgxLoggerLevel } from 'ngx-logger';

/* eslint-disable @typescript-eslint/naming-convention */
export const environment = {
  production: true,
  isE2E: typeof window !== 'undefined' && window['Cypress'],
  firebase: {
    apiKey: 'AIzaSyAL3b0WZvq-yFB0dYb61zrpic1fJlYxqDY',
    authDomain: 'comprarporwhatsapp-19eb8.firebaseapp.com',
    databaseURL: 'https://comprarporwhatsapp-19eb8.firebaseio.com',
    projectId: 'comprarporwhatsapp-19eb8',
    storageBucket: 'comprarporwhatsapp-19eb8.appspot.com',
    messagingSenderId: '998659458507',
    appId: '1:998659458507:web:49a60feef84b6a63e9624e',
    measurementId: 'G-E59RQSPBFQ',
  },
  hereMaps: {
    apiKey: '1Qv5LFgg7oNTGCJsudRUG-M4Hi_ZgogEamAJOZTW5xU',
  },
  googleMaps: {
    apiKey: 'AIzaSyCc1jqQmGyAsnPCrF0GKpFgDXGjqiBHNuk',
  },
  stadiaMaps: {
    apiKey: '82efc3dc-f8d4-45a4-8061-86bbdeb2b029',
  },
  boxful: {
    susbcriptionUrl: 'https://pedixwppsocios.boxful.io/subscription_signups?base_plan=1633',
    profileUrl: 'https://pedixwppsocios.boxful.io/membership/subscriptions',
  },
  salesAdvisorPhone: '543517337251',
  cdnUrl: 'https://cdn.pedix.app',
  debugAnalytics: false,
  siteUrl: 'https://pedix.app',
  menuUrl: 'https://menu.pedix.app',
  adminUrl: 'https://admin.pedix.app',
  emailDomain: 'pedixwpp.com',
  supportEmail: 'soporte@pedixapp.com',
  accountancyEmail: 'soporte@pedixapp.com',
  landingUrl: 'https://info.pedix.app/',
  helpCenterUrl: 'https://pedix.app/centro-ayuda',
  youtubeUrl: 'https://pedix.app/youtube',
  instagramUrl: 'https://pedix.app/instagram',
  helpLinks: {
    stripeUpdatePayment:
      'https://www.notion.so/pedix/Suscripci-n-927e3830026d4c33968172ccd75acb2a#eef90e4383fc4c0d9c12e9a66240c876',
    zenriseUpdatePayment:
      'https://www.notion.so/pedix/Suscripci-n-927e3830026d4c33968172ccd75acb2a#bf79512e396740b683c1d5cde876d099',
  },
  stripePublishableKey:
    'pk_live_51HD4yCIhPiaULCWqCf5AXESTRVkgHRv8kSUlORIHsT3lHqrieRC9GN0zeWy4FuZ87FRuhPC1lKXm7FwAyBFidLMa00kV1AzJFG',
  priceOptions: {
    AR: {
      priceId: 'price_1HMO4mIhPiaULCWqC9gMdw4U',
      price: '$800',
      labelledPrice: 'ochocientos pesos argentinos',
    },
    MX: {
      priceId: 'price_1HMO4lIhPiaULCWqWBQGOZso',
      price: '$330',
      labelledPrice: 'tresciento treinta pesos mexicanos',
    },
    DEFAULT: {
      priceId: 'price_1HMO4mIhPiaULCWqCuhRGyhn',
      price: '$15',
      labelledPrice: 'quince dólares estadounidenses',
    },
  },
  logger: {
    logLevel: NgxLoggerLevel.OFF,
    serverLogLevel: NgxLoggerLevel.WARN,
    disableConsoleLogging: true,
  },
  apiUrl: 'https://pedix.app',
  apiV1Url: 'https://api.pedix.app/v1',
  workerServicesUrl: 'https://services.pedix.app',
  pedixPixelId: '2449935015217527',
  _v: 3, // TODO: remove
  // Food Bank Donation
  foodBankDonationAmount: 750,
};
